:root {
  --main-bg-color: #fff;
  --main-text-color: #444;
  --main-font-size: 14px;

  --h1-font-size: 32px;
  --h2-font-size: 28px;
  --h3-font-size: 24px;
  --h4-font-size: 20px;
  --h5-font-size: 16px;
}

@font-face {
  font-family: 'Roboto Flex';
  src: url('/public/assets/fonts/RobotoFlex-VariableFont_GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,opsz,slnt,wdth,wght.ttf');
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  min-height: 100%;
  position: relative;

  margin: 0;
  font-family:
    'Roboto Flex',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  font-size: var(--main-font-size);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: 500;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 10mm;
  }

  * {
    overflow: visible !important;
  }

  body {
    border: 0;
    background-color: #000;
    display: block;
    padding: 2em;
  }

  header,
  footer,
  aside,
  nav,
  form,
  iframe,
  .menu,
  .hero,
  .adslot,
  .noprint {
    display: none !important;
  }
}
